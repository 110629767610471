<template>
  <el-row>
    <el-col :span="16" :offset="4">
  <div style="min-height: calc(100vh - 60px); margin: 10px 0">
    <div >
      <div style="margin-top: 15px; font-size: 14px">
        <div style="font-size: 20px; font-weight: bold; color: #1E90FF; cursor: pointer;margin-bottom: 20px" >{{ item.title }}</div>
        <span><i class="el-icon-user-solid" style="margin-right: 5px"></i>{{ item.user.nickname }}</span>
        <span><i class="el-icon-time" style="margin-right: 5px; margin-left: 20px"></i>{{ item.createTime }}</span>
      </div>
      <mavon-editor
          class="md"
          :value="item.content"
          :subfield="false"
          :defaultOpen="'preview'"
          :toolbarsFlag="false"
          :editable="false"
          :scrollStyle="true"
          :ishljs="true"
      />
    </div>

    <div style="padding: 30px 0">
      <div style="padding: 10px 0; font-size: 20px; border-bottom: 1px solid #666">评论</div>
      <div style="margin: 10px 0">
        <el-input size="mid" type="textarea" v-model="comment.content" placeholder="请发表评论"></el-input>
        <div style="text-align: right; padding: 10px 0"><el-button type="primary" size="mid" @click="saveComment(item.id)">评论</el-button></div>
      </div>

      <div style="margin: 30px 0">
        <div  v-for="item in comments" style="background-color: #fff; padding: 10px; border-radius: 10px; border-bottom: 1px solid #ddd">
          <div style="padding: 10px 0; display: flex; color: #666;  font-size: 14px">
            <div style="width: 100px; text-align: center" >

              <img  v-if="!item.avatar" src="../../assets/images.jpg" alt="../../assets/images.jpg"  style="width: 50px; height: 50px; border-radius: 50%"></img>
              <img v-if="item.avatar" :src="item.avatar"  alt="../../assets/images.jpg" style="width: 50px; height: 50px; border-radius: 50%"></img>
            </div>
            <div style="flex: 1; line-height: 25px;">
              <div>
                <span style="color: #3F5EFB">{{item.user }}：</span>
                <span>{{ item.content }}</span>
              </div>
              <div style="font-size: 12px; margin-top: 5px; display: flex">
                <div style="flex: 1"><i class="el-icon-time"></i> <span>{{ item.time }}</span></div>
                <div style="width: 100px; text-align: right">
                  <el-button type="text" @click="delComment(item)" style="color: red" v-if="user.nickname === item.user">删除</el-button>
                </div>
              </div>

              <div style="width: 100px; text-align: left">
                <el-button type="text" style="font-size: 16px" @click="handleCommentReply(item)">回复</el-button>
              </div>
              <!--  子集             -->
              <div>
                <div v-if="item.children&&item.children.length>0"  v-for="reply in item.children" style="background-color: #fff; padding: 10px; border-radius: 10px; border-bottom: 1px solid #ddd">
                  <div style="padding: 10px 0; display: flex; color: #666;  font-size: 14px">
                    <div style="width: 100px; text-align: center" >
                      <img  v-if="!reply.avatar" src="../../assets/images.jpg" alt="../../assets/images.jpg"  style="width: 50px; height: 50px; border-radius: 50%"></img>
                      <img v-if="reply.avatar" :src="item.avatar"   alt="../../assets/images.jpg" style="width: 50px; height: 50px; border-radius: 50%"></img>
                    </div>
                    <div style="flex: 1; line-height: 25px;">
                      <div>
                        <span style="color: #3F5EFB">{{reply.user }}<span v-if="reply.toUserUid"  style="color: black">
                          &nbsp;&nbsp;回复&nbsp;&nbsp; <span style="color: #3F5EFB">@{{reply.toUser.nickname}}</span></span>：</span>
                        <span>{{ reply.content }}</span>
                      </div>
                      <div style="font-size: 12px; margin-top: 5px; display: flex">
                        <div style="flex: 1"><i class="el-icon-time"></i> <span>{{ reply.time }}</span></div>
                        <div style="width: 100px; text-align: right">
                          <el-button type="text" @click="delComment(reply)" style="color: red" v-if="user.nickname === reply.user">删除</el-button>
                        </div>
                      </div>

                      <div style="width: 100px; text-align: left">
                        <el-button type="text" style="font-size: 16px" @click="handleCommentReply(item)">回复</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <el-dialog title="回复" :visible.sync="commentReply" width="40%" :close-on-click-modal="false">
      <el-form label-width="30px" size="small" style="width: 100%">
        <el-form-item label="">
          <el-input
              type="textarea"
              placeholder="请输入内容,输入@回复指定的人"
              :rows="5"
              v-model="commentReplyContent.content"
              autocomplete="off"
              @input="handleInput"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="">
          <el-dropdown size="medium" v-if="showUserList && this.userList && this.userList.length > 0" @command="handleSelectUser">
            <i class="el-icon-user">选择你想@的人</i><i class="el-icon-arrow-down el-icon--right"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="user in userList" :key="user.id" :command="user" style="width: 100px">
                <div >

                  <span>{{ user.nickname }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="commentReply = false">取 消</el-button>
        <el-button type="primary" @click="saveCommentReply">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="私信" :visible.sync="privateVis" width="40%" :close-on-click-modal="false">
      <el-form label-width="30px" size="small" style="width: 100%">
        <el-form-item label="">
          <el-input type="textarea" :rows="5" v-model="private.content" autocomplete="off" ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="privateVis = false">取 消</el-button>
        <el-button type="primary" @click="savePrivate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "HomeDetail",
  data() {
    return {
      id: this.$route.query.id,
      type: this.$route.query.type,
      item: {},
      privateVis:false,

      commentReplyContent:{},
      commentReply:false,
      private:{},
      comment:{},
      comments: [],
      form: {},
      command:{},
      url:"",
      showUserList: false,
      userList: [], // 存储系统中的用户列表
      dialogFormVisible: false,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      praise: false
    }
  },
  created() {
    this.load()
    this.loadComment(this.id)
  },
  methods: {
    load() {
      if (this.type==='3'){
        this.url ="/discuss"
      }
      this.request.get(this.url +"/"+ this.id).then(res => {
        this.item = res.data
      })
    },
    handleInput() {
      const content = this.commentReplyContent.content;
      const atIndex = content.lastIndexOf('@');

      if (atIndex !== -1) {

        // 过滤用户列表，显示匹配的用户
        const username = content.substring(atIndex + 1);
        this.loadUserList(username);
        // 显示用户列表
        this.showUserList = true;
      } else {
        // 隐藏用户列表
        this.showUserList = false;
      }
    },
    loadUserList(username) {
      console.log("查询"+username)
      if (!username){
        username="";
      }
      // 调用系统中的用户列表API
      this.request.get("/user/query", {
        params: {
          username: this.username,
        }
      }).then(res => {
        console.log("结果：",res)
        this.userList = res.data
        console.log(this.userList)

      })
    },
    handleSelectUser(command) {
      // 在选择用户时插入用户名到输入框
      const content = this.commentReplyContent.content;
      console.log("内容"+content)
      const atIndex = content.lastIndexOf('@');

      this.commentReplyContent.content = content.substring(0, atIndex + 1) + command.username + ' ';
      this.command = command;

      // 隐藏用户列表
      this.showUserList = false;
    },
    saveComment(id) {
      this.comment.articleId = id
      if (!this.user.username) {
        this.$message.warning("请登录后评论")
        return
      }
      if (this.comment.contentr) {
        this.comment.content = this.form.contentr
      }
      if (!this.comment.content) {
        this.$message.warning("请输入评论")
        return
      }
        this.comment.type = this.type

      this.request.post("/comment", this.comment).then(res => {
        if (res.code === '200') {
          this.$message.success("评论成功")
          this.commentVis = false
          this.comment = {}  // 清空数据
          this.loadComment(id);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    saveCommentReply() {
      if (!this.user.username) {
        this.$message.warning("请登录后评论")
        return
      }
      if (!this.commentReplyContent.content) {
        this.$message.warning("请输入回复")
        return
      }
      this.commentReplyContent.type = this.type
      if (this.command){
        this.commentReplyContent.toUserUid = this.command.id;
        this.commentReplyContent.content = this.commentReplyContent.content.replace("@"+this.command.username,"")
      }
      this.request.post("/comment", this.commentReplyContent).then(res => {
        if (res.code === '200') {
          this.$message.success("回复成功")
          this.commentReply = false
          // 重新加载数据
          this.loadComment(this.commentReplyContent.articleId)
          this.commentReplyContent = {}  // 清空数据
          this.command = {};
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    delComment(item) {
      this.request.delete("/comment/" + item.id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.loadComment(item.articleId)
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    loadComment(id) {
      this.request.get("/comment/article/"+this.type+"/" + id).then(res => {
        this.comments = res.data
      })
    },
    handleCommentReply(item,userId) {
      console.log(item)
      console.log(userId)
      this.commentReplyContent.articleId = item.articleId
      this.commentReplyContent.toUid = item.id
      this.commentReplyContent.toUserUid = userId;
      this.commentReply = true
    },
    savePrivate() {
      if (!this.user.username) {
        this.$message.warning("请登录后私信")
        return
      }
      if (this.private.contentr) {
        this.private.content = this.form.contentr
      }
      if (!this.private.content) {
        this.$message.warning("请输入私信")
        return
      }
      this.private.type = 3
      this.request.post("/comment", this.private).then(res => {
        if (res.code === '200') {
          this.$message.success("私信成功")
          this.privateVis = false
          this.private = {}  // 清空数据
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePrivate(id){
      this.private.articleId = id
      this.privateVis = true
    },
  }
}
</script>

<style scoped>

</style>
